import { Button, Card, H1, H3, H4, Tag } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import EventsCard from "./EventsCard";
import { Footer } from "./Footer";

export const SiteUnderConstructionCard = () => {
  // Existing states
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Existing images loading
    const image = new Image();
    image.src = "/work-deadline-4.svg";
    image.onload = () => setImageLoaded(true);
  }, []);

  // Return null or loading spinner if images are not loaded
  if (!imageLoaded) {
    return null; // or return a loading spinner or any placeholder content
  }

  return (
      <div className="site-container">
        <div className="container">
          <img
              src="/work-deadline-4.svg"
              alt="Work Deadline"
              className="deadline-image" // Use className for styling
          />
          <H1 className="card-header">РАСПИСАНИЕ МАСТЕР-КЛАССОВ</H1>
          <div className="row">
            <Card className="card-inside-container" elevation={4}>
              <Tag className="location-tag" large>ЧЕЛЯБИНСК</Tag>
              <Tag className="location-product-tag" large intent={"warning"}>СОЛИСТЫ ТЕАТРА TODES</Tag>
              <H3>МАСТЕР-КЛАСС АЛЕКСЕЯ ЗЕЛЕНЕЦКОГО</H3>
              <H4>22 сентября 2024</H4>
              <p className="event-description">
                Развить свое актерское мастерство, научиться не бояться сцены, получить знания по сценической речи - все
                это на авторском курсе Алексея Зеленецкого: «Актерское мастерство для танцора. Психологическая
                подготовка к выступлению»
              </p>
              <Button
                  icon="shopping-cart"
                  text="Оформить билет"
                  intent="success"
                  large
                  onClick={() =>
                      (window.location.href =
                          "https://masterclass.todes.ru/event/9E468I")
                  }
              />
            </Card>
            <Card className="card-inside-container" elevation={4}>
              <Tag className="location-tag" large>КРАСНОГОРСК</Tag>
              <Tag className="location-product-tag" large intent={"warning"}>AFRO</Tag>
              <H3>AFRO TODES 2.0</H3>
              <H4>29 сентября 2024</H4>
              <p className="event-description">
                Погружение в африканские стили с амбассадорами афростилей в России и странах СНГ. Впервые в рамках
                мероприятия AfroJam and DJ!
              </p>
              <Button
                  icon="shopping-cart"
                  text="Оформить билет"
                  intent="success"
                  large
                  onClick={() =>
                      (window.location.href =
                          "https://masterclass.todes.ru/event/RB1OYY")
                  }
              />
            </Card>
          </div>
          <div className="row">
            <Card className="card-inside-container" elevation={4}>
              <Tag className="location-tag" large>ЧЕЛЯБИНСК</Tag>
              <Tag className="location-product-tag" large intent={"warning"}>СОЛИСТЫ ТЕАТРА TODES</Tag>
              <H3>МАСТЕР-КЛАСС ЮЛИИ ФИЛИППОВОЙ-БУГАКОВОЙ</H3>
              <H4>06 октября 2024</H4>
              <p className="event-description">
                Мастер-класс от ведущей солистки Театра TODES Юлии Филипповой для детей старше 13 лет.
              </p>
              <Button
                  icon="shopping-cart"
                  text="Оформить билет"
                  intent="success"
                  large
                  onClick={() =>
                      (window.location.href =
                          "https://masterclass.todes.ru/event/GQRSL2")
                  }
              />
            </Card>
            <Card className="card-inside-container" elevation={4}>
              <Tag className="location-tag" large>ЧЕЛЯБИНСК</Tag>
              <Tag className="location-product-tag" large intent={"warning"}>LADY</Tag>
              <H3>МАСТЕР-КЛАСС ЮЛИИ ФИЛИППОВОЙ-БУГАКОВОЙ</H3>
              <H4>06 октября 2024</H4>
              <p className="event-description">
                Мастер-класс от ведущей солистки Театра TODES Юлии Филипповой - это воплощение гармонии, женственности и
                спортивной закалки. На своем уроке она раскроет секреты выразительности движений и поможет каждому
                развить свою индивидуальность в танце. Присоединяйтесь и откройте новые грани своего таланта!
              </p>
              <Button
                  icon="shopping-cart"
                  text="Оформить билет"
                  intent="success"
                  large
                  onClick={() =>
                      (window.location.href =
                          "https://masterclass.todes.ru/event/QUYQTK")
                  }
              />
            </Card>
          </div>
          <div className="row">
            <Card className="card-inside-container" elevation={4}>
              <Tag className="location-tag" large>САРАТОВ</Tag>
              <Tag className="location-product-tag" large intent={"warning"}>СОЛИСТЫ ТЕАТРА TODES</Tag>
              <H3>МАСТЕР-КЛАСС ЮЛИИ ФИЛИППОВОЙ-БУГАКОВОЙ</H3>
              <H4>20 октября 2024</H4>
              <p className="event-description">
                Мастер-класс от ведущей солистки Театра TODES Юлии Филипповой для детей старше 12 лет.
              </p>
              <Button
                  icon="shopping-cart"
                  text="Оформить билет"
                  intent="success"
                  large
                  onClick={() =>
                      (window.location.href =
                          "https://masterclass.todes.ru/event/RYLJG3")
                  }
              />
            </Card>
            <Card className="card-inside-container" elevation={4}>
              <Tag className="location-tag" large>САРАТОВ</Tag>
              <Tag className="location-product-tag" large intent={"warning"}>LADY</Tag>
              <H3>МАСТЕР-КЛАСС ЮЛИИ ФИЛИППОВОЙ-БУГАКОВОЙ</H3>
              <H4>20 октября 2024</H4>
              <p className="event-description">
                Мастер-класс от ведущей солистки Театра TODES Юлии Филипповой - это воплощение гармонии, женственности и
                спортивной закалки. На своем уроке она раскроет секреты выразительности движений и поможет каждому
                развить свою индивидуальность в танце. Присоединяйтесь и откройте новые грани своего таланта!
              </p>
              <Button
                  icon="shopping-cart"
                  text="Оформить билет"
                  intent="success"
                  large
                  onClick={() =>
                      (window.location.href =
                          "https://masterclass.todes.ru/event/13K5PV")
                  }
              />
            </Card>
          </div>
        </div>
        {/*<EventsCard/>*/}
        <Footer/>
      </div>
  );
};